import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import NumberFormat from 'react-number-format'

const NumberUpDown = (props) => {
    const {
        placeholder,
        // min,
        max,
        step,
        onChange,
        value,
        className
    } = props

    const [currentValue, setCurrentValue] = useState(0)

    useEffect(() => {
        const inputValue = value
        if (inputValue > max) {
            setCurrentValue(max)
        }
        else {
            if (inputValue !== currentValue) {
                setCurrentValue(inputValue)
            }
        }
        // eslint-disable-next-line
    }, [value])

    const onIncrease = () => {
        let currentVal = parseInt(currentValue)
        if (currentVal + step >= max) {
            setCurrentValue(max)
            onChange(max)
        }
        else {
            const newValue = currentVal + step
            setCurrentValue(newValue)
            onChange(newValue)
        }
    }

    const onDecrease = () => {
        let currentVal = parseInt(currentValue)
        if (currentVal <= step) {
            setCurrentValue(0)
            onChange(0)
        }
        else {
            const newValue = currentVal - step
            setCurrentValue(newValue)
            onChange(newValue)
        }
    }

    return (
        <div className={className + ' input-group flex-nowrap'}>
            <span className="input-group-text cursor-point" onClick={onDecrease}>-</span>
            <NumberFormat
                placeholder={placeholder}
                className="form-control"
                // thousandSeparator={"."}
                // decimalSeparator={","}
                allowNegative={false}
                defaultValue={0}
                decimalScale={0}
                value={currentValue}
                onValueChange={val => {
                    if (val.value !== '') {
                        if (val.value > max) {
                            setCurrentValue(max)
                            onChange(max)
                        }
                        else {
                            setCurrentValue(val.value)
                            onChange(parseInt(val.value))
                        }
                    }
                    else {
                        setCurrentValue(0)
                        onChange(0)
                    }
                }}
            />
            <span className="input-group-text cursor-point" onClick={onIncrease}>+</span>
        </div>
    )
}

NumberUpDown.propTypes = {
    placeholder: PropTypes.string,
    min: PropTypes.number,
    max: PropTypes.number,
    step: PropTypes.number,
    onChange: PropTypes.func,
    value: PropTypes.number,
    className: PropTypes.string
}

NumberUpDown.defaultProps = {
    placeholder: '0',
    min: 0,
    max: 1_000_000,
    step: 1,
    onChange: () => { },
    value: 0,
    className: ''
}

export default NumberUpDown