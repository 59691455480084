import PropTypes from 'prop-types'
import React from 'react'
import { IoCartOutline } from 'react-icons/io5'
import { FormatNumber, IconButton } from './../../../../../components'

const noImgUrl = '/assets/images/no-img.jpg'

const apiUrl = window.apiUrl

const List = (props) => {
    const onShowDetail = (productId) => {
        props.onShowDetail(productId)
    }

    const renderList = () => {
        if (props.products === null) return <div className="col-12 text-center">
            <div className="spinner-border text-primary me-1" role="status"></div>
        </div>
        else if (props.products.length === 0) return <div className="col-12">
            <p className="mb-0 text-center">Không tìm thấy dữ liệu</p>
        </div>
        else return props.products.map(item => {
            const avatarUrl = (item.AvatarUrl === '' || item.AvatarUrl === null ? noImgUrl : `${apiUrl}/admin/storage/thumbnail?url=${item.AvatarUrl}&size=300`)
            return <div className="col" key={item.ProductId}>
                <p className="bg-img cursor-point" style={{ background: `url('${avatarUrl}')` }} onClick={() => onShowDetail(item.ProductId)}></p>
                <p className="text-primary cursor-point mb-1 trunk" onClick={() => onShowDetail(item.ProductId)}>{item.ProductName}</p>
                <p className="mb-1">{item.ProductCode}</p>
                <p className="mb-1">Giá CTV <span className="float-end"><FormatNumber>{item.MinPrice}</FormatNumber></span></p>
                <p className="text-danger mb-1">Bán lẻ đề xuất <span className="float-end"><FormatNumber>{item.OutputPrice}</FormatNumber></span></p>
                <p className="mb-1">Kho hàng <span className="float-end"><FormatNumber>{item.Quantity}</FormatNumber></span></p>
                <IconButton
                    icon={<IoCartOutline />}
                    text="Thêm vào giỏ hàng"
                    variant="success"
                    className="w-100"
                    onClick={(setIsBusy) => props.onAddToCart(item.ProductId, setIsBusy)}
                />
            </div>
        })
    }

    return (
        <div className="row gy-3 row-cols-sm-2 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 row-cols-xxl-6">
            {renderList()}
        </div>
    )
}

List.propTypes = {
    products: PropTypes.array,
    onAddToCart: PropTypes.func,
    onShowDetail: PropTypes.func
}

List.defaultProps = {
    products: [],
    onAddToCart: () => { },
    onShowDetail: () => { }
}

export default List